
import { Options, Vue } from 'vue-class-component';

import Grid from '@/components/grid/grid.vue';

import { event } from 'vue-gtag';
import { Vue3Lottie } from 'vue3-lottie';
import LoadJSON from '../load2.json';

@Options({
    components: {
        Grid,
        Vue3Lottie
    },
    data() {
        return {
          LoadJSON,
        }
    },
    computed: {
        CMS_URL () {
            return this.$store.getters.getCMSURL;
        },
        directory () {
            return this.$store.getters.getDirectory;
        }
    }/*,
    watch: {
        directory(newValue) {
          this.$store.commit("setDirectory", newValue);
        }
    }*/
})
export default class DirectoryView extends Vue {

    CMS_URL;
    directory;    
    activePost = null;
    total = 20;
    numMore;

    created() {
        this.numMore = this.total;
        this.getDirectory();
    }

    mounted() {
        let cookies = (this.$parent as any).$root.cookies,
          gdprCookie = cookies.get("gdprCookie");

        if(gdprCookie == 'true') {

          event(null, { 
            event: null, // Event type [default = 'interaction'] (Optional)
            category: 'Directory',
            action: 'click',
            label: 'Directory View',
            value: 5000,
            noninteraction: false, // Optional
          });

        }
    }

    getDirectory() {
        if(!this.directory) {
            this.axios.get(this.CMS_URL + 'mt7rk/directory_draft').then((response) => {
                //console.log(JSON.parse(response.data));
                this.$store.commit('setDirectory', JSON.parse(response.data).posts);
                //console.log(this.directory);
            });
        }
    }

    goToDirectory(post) {
        this.$store.commit('setActivePost', post);
        this.$router.push({
            name: 'directorypost',
            params: { id: post.ID }
        });
    }

    getPostByID(id) {
        this.axios.get(this.CMS_URL + 'mt7rk/get_post_by_id&id=' + id).then((response) => {
          //console.log(response.data);
          //this.$store.commit('setActivePost', JSON.parse(response.data));
          this.activePost = JSON.parse(response.data);
          //console.log(this.activePost.post_content);
        });
    }

    overAnim(id) {
        (this as any).$refs.loadCom.play();
    }

    outAnim(id) {
        (this as any).$refs.loadCom.stop();
    }

    loadMore(e) {
        this.numMore = this.numMore + this.total;
        this.$forceUpdate();
        e.preventDefault();
    }

}

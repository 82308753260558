import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import axios from 'axios';


import BlogView from '../views/magazine/blog.vue';
import BlogPost from '../views/magazine/_id/_id.vue';
import BlogPostDraft from '../views/magazine/draft/_id/_id.vue';
import Query from '../views/search/_query/_query.vue';
import DirectoryView from '../views/directory/directory.vue';
import DirectoryTestView from '../views/directest/directory.vue';
import DirectorySearchView from '../views/directory/search/_query/_query.vue';
import DirectoryPost from '../views/directory/_id/_id.vue';
import DirectoryPostDraft from '../views/directory/draft/_id/_id.vue';
import AwardsView from '../views/showcase/awards.vue';
import AwardsPost from '../views/showcase/_id/_id.vue';
import TagPage from '../views/tag/_name/_name.vue';
import CatPage from '../views/cat/_name/_name.vue';
import News from '../views/news/news.vue';
import NewsPost from '../views/news/_id/_id.vue';
import NewsPostDraft from '../views/news/draft/_id/_id.vue';
import Content from '../views/content/content.vue';
import Community from '../views/community/community.vue';
import PageView from '../views/page/_id/_id.vue';
import AuthorView from '../views/author/_id/_id.vue';

const CMS_URL = 'http://localhost/public/cms/?rest_route=/'
const BASE_URL = 'http://localhost/';
const title = 'MT7RK - Everything Arab and Animated';
const description = "mt7rk is an online hub about the animation industry and all its affiliations in the Arab world. From visual content, event coverage, long reads, reflections, spotlights, and more, mt7rk seeks to be an essential resource for animators wanting to keep up to date on news about animation in the region and a platform that inspire connections among creators across the region.";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'magazine',
    component: BlogView,
    meta: { 
      title: title, 
      isPost: false,
      metaTags: [
        //general
        {
          name: 'description',
          content: description
        },
        //facebook
        {
          vmid: "fb-locale",
          name: "og:locale",
          content: "en"
        },{
          vmid: "fb-type",
          name: "og:type",
          content: "website"
        },{
          vmid: "fb-url",
          name: "og:url",
          content: BASE_URL
        },{
          vmid: "fb-image",
          name: "og:image",
          content: BASE_URL + 'public/mt7rk-share.jpg'
        },{
          vmid: "fb-site_name",
          name: "og:site_name",
          content: title
        },{
          vmid: "fb-title",
          name: "og:title",
          content: title
        },{
          vmid: "fb-description",
          name: "og:description",
          content: description
        },{
          vmid: "tw-description",
          name: "twitter:description",
          content: description
        },{
          vmid: "tw-title",
          name: "twitter:title",
          content: title
        },{
          vmid: "tw-card",
          name: "twitter:card",
          content: "summary"
        },{
          vmid: "tw-image",
          name: "twitter:image:src",
          content: BASE_URL + 'public/mt7rk-share.jpg'
        },{
          vmid: "tw-domain",
          name: "twitter:domain",
          content: BASE_URL
        }
      ]
    }
  },
  {
    path: '/magazine/:id',
    name: 'magazinepost',
    component: BlogPost,
    meta: {
      metaTags: [ {
        vmid: "fb-locale",
        name: "og:locale",
        content: "en"
      },{
        vmid: "fb-type",
        name: "og:type",
        content: "website"
      },{
        vmid: "fb-url",
        name: "og:url",
        content: BASE_URL
      },{
        vmid: "fb-site_name",
        name: "og:site_name",
        content: title
      },{
        vmid: "tw-card",
        name: "twitter:card",
        content: "summary"
      },{
        vmid: "tw-domain",
        name: "twitter:domain",
        content: BASE_URL
      }
    ]
    }
  },
  {
    path: '/magazine/draft/:id',
    name: 'magazinepostdraft',
    component: BlogPostDraft
  },
  {
    path: '/content',
    name: 'content',
    component: Content
  },
  {
    path: '/community',
    name: 'community',
    component: Community
  },
  {
    path: '/tag/:name',
    name: 'tag',
    component: TagPage
  },
  {
    path: '/cat/:name',
    name: 'cat',
    component: CatPage
  },
  {
    path: '/search/:query',
    name: 'search',
    component: Query
  },
  {
    path: '/directory',
    name: 'directory',
    component: DirectoryView,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
  ,
  {
    path: '/directest',
    name: 'directest',
    component: DirectoryTestView,
  },
  {
    path: '/directory/:id',
    name: 'directorypost',
    component: DirectoryPost
  },
  {
    path: '/directory/draft/:id',
    name: 'directorypostdraft',
    component: DirectoryPostDraft
  },
  {
    path: '/directory/search/:query',
    name: 'directorysearch',
    component: DirectorySearchView
  },
  {
    path: '/showcase',
    name: 'showcase',
    component: AwardsView
  },
  {
    path: '/showcase/:id',
    name: 'showcasepost',
    component: AwardsPost
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/news/:id',
    name: 'newspost',
    component: NewsPost
  },
  {
    path: '/news/draft/:id',
    name: 'newspostdraft',
    component: NewsPostDraft
  },
  {
    path: '/page/:id',
    name: 'pageView',
    component: PageView
  },
  {
    path: '/author/:id',
    name: 'authorView',
    component: AuthorView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// This callback runs before every route change, including on page load.
/*router.beforeEach(async(to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  console.log(to);
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    (document as any).title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    (document as any).title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(to.params.id) {
    console.log("we got params!");
    console.log(to.params.id);
    console.log(router);

    const response = await (axios.get(CMS_URL + 'mt7rk/get_post_by_id&id=' + to.params.id) as any);

    const resp = JSON.parse((response.data as any));

    if(resp.ID) {

      //title
      const title = "MT7RK - "+resp.post_title;
      (document as any).title = title; //title 

      const fbTitle = document.createElement('meta');
      fbTitle.setAttribute('vmid', "fb-title");
      fbTitle.setAttribute('name', 'og:title');
      fbTitle.setAttribute('data-vue-router-controlled', '');
      fbTitle.setAttribute('content', title);
      document.head.appendChild(fbTitle);

      const twTitle = document.createElement('meta');
      twTitle.setAttribute('vmid', "tw-title");
      twTitle.setAttribute('name', 'twitter:title');
      twTitle.setAttribute('data-vue-router-controlled', '');
      twTitle.setAttribute('content', title);
      document.head.appendChild(twTitle);      

      //description
      const description = document.createElement('meta');
      description.setAttribute('description', resp.custom_fields.intro_text);
      description.setAttribute('data-vue-router-controlled', '');
      document.head.appendChild(description);

      const fbDesc = document.createElement('meta');
      fbDesc.setAttribute('vmid', "fb-description");
      fbDesc.setAttribute('name', 'og:description');
      fbDesc.setAttribute('data-vue-router-controlled', '');
      fbDesc.setAttribute('content', resp.custom_fields.intro_text);
      document.head.appendChild(fbDesc);

      const twDesc = document.createElement('meta');
      twDesc.setAttribute('vmid', "tw-description");
      twDesc.setAttribute('name', 'twitter:description');
      twDesc.setAttribute('data-vue-router-controlled', '');
      twDesc.setAttribute('content', resp.custom_fields.intro_text);
      document.head.appendChild(twDesc);

      //image
      const fbImage = document.createElement('meta');
      fbImage.setAttribute('vmid', 'fb-image');
      fbImage.setAttribute('name','og:image');
      fbImage.setAttribute('data-vue-router-controlled', '');
      fbImage.setAttribute('content', resp.custom_fields.image.sizes.medium_large);
      document.head.appendChild(fbImage);

      const twImage = document.createElement('meta');
      twImage.setAttribute('vmid', 'tw-image');
      twImage.setAttribute('name','twitter:image:src');
      twImage.setAttribute('data-vue-router-controlled', '');
      twImage.setAttribute('content', resp.custom_fields.image.sizes.medium_large);
      document.head.appendChild(twImage);



    } else {
      console.log("not found");
    }

    console.log(resp);

  } 

  if(!nearestWithMeta) {
    return;
  } else {
    (nearestWithMeta.meta.metaTags as any).map(tagDef => {
      const tag = document.createElement('meta');
  
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });
  
      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');
  
      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));
  
    return;
  }
  

  // Turn the meta tag definitions into actual elements in the head.
  

});*/

export default router


//import * as Vue from "vue";
import { Vue, Options }  from "vue-class-component";
import  Post from "@/components/post/post.vue";

@Options({
  components: {
    Post
  },
  props: ['posts', 'section']
})
export default class Grid extends Vue {

  posts;
  section;

  /*created() {
    console.log("in grid");
    console.log(this.posts);
  }*/
  mounted() {
    this.$forceUpdate();
  }

  goToBlog(post) {
    this.$store.commit('setActivePost', post);
    this.$router.push({
      name: 'blogpost',
      params: { id: post.ID }
    });
  }

  goToTag(tag,e) {
    this.$router.push({
      name: 'tag',
      params: { name: tag }
    });
    e.preventDefault();
  }
}

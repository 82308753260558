
import { Options, Vue } from 'vue-class-component';
import Grid from '@/components/grid/grid.vue';

import { event } from 'vue-gtag';
import { Vue3Lottie } from 'vue3-lottie';
import LoadJSON from '../../load2.json';

@Options({
  components: {
    Grid,
    Vue3Lottie
  },
  data() {
      return {
        LoadJSON,
      }
  },
  computed: {
    CMS_URL () {
      return this.$store.getters.getCMSURL;
    }
  },
  watch: {
    '$route' (to) {
      this.query = to.params.query;
      this.getResults();
    }
  }
})
export default class Query extends Vue {

  CMS_URL;
  posts;
  query;
  total = 20;
  numMore;

  created() {
    this.numMore = this.total;
    this.query = this.$route.params.query;

    //console.log(this.query);

    this.getResults();

  }

  mounted() {
    let cookies = (this.$parent as any).$root.cookies,
      gdprCookie = cookies.get("gdprCookie"),
      search = this.$route.params.query;

    if(gdprCookie == 'true') {

      event(null, { 
        event: null, // Event type [default = 'interaction'] (Optional)
        category: 'Search',
        action: 'click',
        label: 'Search View: '+search,
        value: 5000,
        noninteraction: false, // Optional
      });

    }
  }

  getResults() {
    this.axios.get(this.CMS_URL + 'mt7rk/search&query=' + this.query).then((response) => {
      this.posts = JSON.parse(response.data).posts;
      //console.log(this.posts);
      this.$forceUpdate();
    });
  }

   goToBlog(post) {
    this.$store.commit('setActivePost', post);
    this.$router.push({
      name: 'blogpost',
      params: { id: post.ID }
    });
  }

  goToTag(tag) {
    //console.log(tag);
    this.$router.push({
      name: 'tag',
      params: { name: tag }
    });
  }

  overAnim(id) {
      (this as any).$refs.loadCom.play();
  }

  outAnim(id) {
      (this as any).$refs.loadCom.stop();
  }

  loadMore(e) {
      this.numMore = this.numMore + this.total;
      this.$forceUpdate();
      e.preventDefault();
  }

}

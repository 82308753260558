
import { Options, Vue } from 'vue-class-component';
import Grid from '@/components/grid/grid.vue';

import { event } from 'vue-gtag';
import { Vue3Lottie } from 'vue3-lottie';
import LoadJSON from '../load2.json';

@Options({
  components: {
    Grid,
    Vue3Lottie
  },
  data() {
      return {
        LoadJSON,
      }
  },
  computed: {
    CMS_URL () {
      return this.$store.getters.getCMSURL;
    },
    posts () {
      return this.$store.getters.getPosts;
    }
  }
})
export default class Content extends Vue {

  CMS_URL;
  posts;
  list = [];
  total = 20;
  numMore;

  created() {
    this.numMore = this.total;
    this.getPosts();
  }

  mounted() {
    let cookies = (this.$parent as any).$root.cookies,
      gdprCookie = cookies.get("gdprCookie");

    if(gdprCookie == 'true') {

      event(null, { 
        event: null, // Event type [default = 'interaction'] (Optional)
        category: 'Content',
        action: 'click',
        label: 'Content View',
        value: 5000,
        noninteraction: false, // Optional
      });

    }
  }

  getPosts() {
    if(!this.posts) {
      this.axios.get(this.CMS_URL + 'mt7rk/posts').then((response) => {
        //console.log(response.data);
        this.$store.commit('setPosts', JSON.parse(response.data).posts);
        //console.log(this.posts);
      });
    } 
  }

  goToBlog(post) {
    this.$store.commit('setActivePost', post);
    this.$router.push({
      name: 'blogpost',
      params: { id: post.ID }
    });
  }

  goBack() {
    if((window as any).history.state.back) {
      this.$router.go(-1);
    } else {
      this.$router.push({
        name: 'blog',
      });
    }
    //
  }

  overAnim(id) {
    (this as any).$refs.loadCom.play();
  }

  outAnim(id) {
    (this as any).$refs.loadCom.stop();
  }

  loadMore(e) {
    this.numMore = this.numMore + this.total;
    this.$forceUpdate();
    e.preventDefault();
  }
}


import { Options, Vue } from 'vue-class-component';

import { event } from 'vue-gtag';

@Options({
  components: {
    
  },
  computed: {
    CMS_URL () {
      return this.$store.getters.getCMSURL;
    }
  }
})
export default class PageView extends Vue {

  CMS_URL;
  activePost = null;
  relatedPost = null;
  scrolled = false;
  created() {
    //console.log(this.$route.params.id);
    this.getPost();
  }

  mounted() {
    //window.addEventListener('scroll', this.handleScroll);
    let cookies = (this.$parent as any).$root.cookies,
      gdprCookie = cookies.get("gdprCookie"),
      id = this.$route.params.id;

    if(gdprCookie == 'true') {

      event(null, { 
        event: null, // Event type [default = 'interaction'] (Optional)
        category: 'Author',
        action: 'click',
        label: 'Author View: '+id,
        value: 5000,
        noninteraction: false, // Optional
      });

    }
  }

  getPost() {
    let id = this.$route.params.id;
    //if(!this.activePost) {
      //console.log('get post--',id);
      this.getPostByID(id);
      this.getRelatedPost(id);
    /*} else {
      console.log(this.activePost);
      this.getRelatedPost(this.activePost.custom_fields.author.ID, id);
    }*/
  }

  getPostByID(id) {
    this.axios.get(this.CMS_URL + 'mt7rk/get_post_by_id&id=' + id).then((response) => {
      //console.log(response.data);
      //this.$store.commit('setActivePost', JSON.parse(response.data));
      this.activePost = JSON.parse(response.data);
      //console.log(this.activePost);
    });
  }

  getRelatedPost(id) {
    this.axios.get(this.CMS_URL + 'mt7rk/get_related_posts&id=' + id).then((response) => {
      //console.log(response.data);
      //this.$store.commit('setActivePost', JSON.parse(response.data));
      this.relatedPost = response.data;
      //console.log(this.relatedPost);
    });
  }

  goToBlog(post,e) {
    this.$store.commit('setActivePost', post);
    this.$router.push({
      name: 'magazinepost',
      params: { id: decodeURIComponent(post.post_name) }
    });
    e.preventDefault();
  }

}
